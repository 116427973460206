import React from "react"
import clsx from "clsx"

import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import Img from "./Img"

import * as styles from "./banner.module.scss"
import { handleClickBanner } from "../../../helpers/WatcherDL/utils/handleClickBanner"
import { useObserver } from "../../../hooks/useObserver"
import { TypeBannerPropsWithElements } from "../helpers"

const buttonText = "Оформить карту"

export function Banner({ title, description, orderNum }: TypeBannerPropsWithElements) {
  const isVisible = useObserver(["app", "calculator", "reviewCard"])

  return (
    <section id="banner" className={styles.section} data-exclude={orderNum}>
      <Container className={styles.root}>
        <Container className={styles.container}>
          <Img alt="bg image" className={styles.img} />
          <div className={styles.text}>
            <h1>{title}</h1>
            <div>{description}</div>
          </div>
          <Button className={styles.btn} onClick={() => handleClickBanner("click_cta")}>
            {buttonText}
          </Button>
        </Container>
      </Container>
      <div className={clsx(styles.btnBlock, { [styles.hideMobBlock]: isVisible })}>
        <Container>
          <Button onClick={() => handleClickBanner("sticky")} className={styles.mobBtn}>
            {buttonText}
          </Button>
        </Container>
      </div>
    </section>
  )
}
