import React from "react"
import type { ReactNode } from "react"

export type CashbackItem = {
  title: ReactNode
  hint: ReactNode
}

export const DEFAULT_ITEMS: [CashbackItem, CashbackItem, CashbackItem] = [
  {
    title: (
      <>
        До 10% <br />с подпиской
      </>
    ),
    hint: (
      <>
        Получайте повышенный кэшбэк до&nbsp;10% на покупки у партнеров на сумму свыше 50 000 рублей
      </>
    ),
  },
  {
    title: (
      <>
        От 2 до 6% у&nbsp;партнеров <br /> без подписки
      </>
    ),
    hint: (
      <>
        — 10% за оплату поездки на такси «ЯндексGo».
        <br />
        Покупайте в магазинах-партнерах и получайте выгодный кэшбэк:
        <br /> — до 6% за товар дороже 10 000 рублей,
        <br /> — от 2 до 4%, если сумма покупки не превышает 9999 рублей.
      </>
    ),
  },
  {
    title: "1% за покупки не у&nbsp;партнеров от 1000 руб. и оплату услуг в приложении",
    hint: (
      <>
        За каждую покупку от 1000 рублей не у партнеров начисляется 1%. Оплачивайте в приложении
        услуги ЖКХ, мобильную связь, интернет и&nbsp;т.д.
      </>
    ),
  },
]
