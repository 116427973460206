import React from "react"

export const InstallmentIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_603_802)">
      <path d="M45 -5H-5V45H45V-5Z" fill="#FF4E50" />
      <path d="M-5.4082 20L20.0008 5.27002V26.442L1.9058 37.271L-5.4082 20Z" fill="#003790" />
      <path
        d="M41.5728 14.151L20.0918 26.442L56.6048 48.446L56.2578 26.65L41.5728 14.151Z"
        fill="white"
      />
      <path d="M39.119 -4.96899L39.483 15.346L20 5.27001L39.119 -4.96899Z" fill="#BE2224" />
    </g>
    <defs>
      <clipPath id="clip0_603_802">
        <rect width="40" height="40" rx="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
