import React from "react"

export const WithDrawingIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_603_794)">
      <path d="M45 -5H-5V45H45V-5Z" fill="#FF4E50" />
      <path d="M42.8107 29.057L22.0137 18.428L24.0257 44.528L42.8107 29.057Z" fill="white" />
      <path
        d="M-2.01367 19.33L22.0123 18.428L5.24133 40L-0.000671864 30.23L-2.01367 19.33Z"
        fill="#F23A3E"
      />
      <path
        d="M3.39648 34.591L22.0135 18.428L18.5535 43.313L7.25448 40L3.39648 34.591Z"
        fill="#BE2224"
      />
      <path d="M14.2988 42.978L22.0138 18.428L23.8588 42.013L14.2988 42.978Z" fill="#003790" />
    </g>
    <defs>
      <clipPath id="clip0_603_794">
        <rect width="40" height="40" rx="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
