import React from "react"

export const CashBackIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_603_785)">
      <path d="M45 -5H-5V45H45V-5Z" fill="#FF4E50" />
      <path
        d="M8.51027 -10.524L32.1153 12.741L35.0153 15.443L19.2443 30.817L-1.55273 12.956L8.51027 -10.524Z"
        fill="#F23A3E"
      />
      <path
        d="M-0.963562 5.49292L14.3784 19.7449L14.9934 23.3279L4.28844 34.0699L-1.10156 27.5849L-0.963562 5.49292Z"
        fill="#BE2224"
      />
      <path
        d="M41.6092 45.1386L14.377 19.7457L-3.46689 38.8821L23.7653 64.275L41.6092 45.1386Z"
        fill="#003790"
      />
      <path
        d="M59.3475 38.135L32.1152 12.7421L14.2721 31.8777L41.5043 57.2706L59.3475 38.135Z"
        fill="white"
      />
      <path
        d="M20.5661 32.957C21.3653 32.957 22.0131 32.3091 22.0131 31.51C22.0131 30.7108 21.3653 30.063 20.5661 30.063C19.767 30.063 19.1191 30.7108 19.1191 31.51C19.1191 32.3091 19.767 32.957 20.5661 32.957Z"
        fill="#003790"
      />
    </g>
    <defs>
      <clipPath id="clip0_603_785">
        <rect width="40" height="40" rx="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
