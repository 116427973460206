import React from "react"
import { ICONS } from "./helpers"

type Props = {
  icon: string
}

export const IconComp = ({ icon }: Props) => {
  const Icon = ICONS[icon]

  return <Icon />
}
