import React from "react"

export const ServiceIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_603_775)">
      <path
        d="M50.8705 2.32558L2.32812 -10.8689L-10.8664 37.6735L37.676 50.868L50.8705 2.32558Z"
        fill="#FF4E50"
      />
      <path
        d="M9.85563 -5.06702L-2.60938 7.29498L10.0186 21.718L9.85563 -5.06702Z"
        fill="#BE2224"
      />
      <path d="M-1.92516 32.774L-2.03516 7.95203L10.0188 21.718L-1.92516 32.774Z" fill="#F23A3E" />
      <path
        d="M30.5955 41.907L34.1525 40.054L44.7975 16.511L10.0195 21.718L-2.60352 33.402L15.7565 43.28L30.5955 41.907Z"
        fill="#003790"
      />
      <path
        d="M44.1455 13.125L21.1495 34.908L10.0195 21.718L33.5825 0L44.1455 13.125Z"
        fill="white"
      />
      <path
        d="M21.823 30.0599C22.8298 30.0599 23.646 29.2438 23.646 28.2369C23.646 27.2301 22.8298 26.4139 21.823 26.4139C20.8162 26.4139 20 27.2301 20 28.2369C20 29.2438 20.8162 30.0599 21.823 30.0599Z"
        fill="#FF4E50"
      />
    </g>
    <defs>
      <clipPath id="clip0_603_775">
        <rect width="40" height="40" rx="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
