import React from "react"

export const TransferIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_603_765)">
      <path d="M45 -5H-5V45H45V-5Z" fill="#FF4E50" />
      <path
        d="M-4.12891 32.2L0.222094 17.016L25.9571 44.1L20.4201 48.428L-4.12891 32.2Z"
        fill="#BE2224"
      />
      <path d="M28.5078 30.887L38.9948 40.755L41.4148 17.557L28.5078 30.887Z" fill="#F23A3E" />
      <path
        d="M41.5101 -1.677L28.4281 10.126L4.02509 3.836L-3.00391 13.591L20.3451 38.375L20.0001 22.099L28.5071 30.887L41.5521 19.036L41.5101 -1.677Z"
        fill="#003790"
      />
      <path
        d="M9.49623 -8.42798L28.4272 10.126L28.5062 30.887L1.99023 3.49502L9.49623 -8.42798Z"
        fill="white"
      />
      <path
        d="M22.5799 13.711C23.5872 13.711 24.4039 12.8944 24.4039 11.887C24.4039 10.8796 23.5872 10.063 22.5799 10.063C21.5725 10.063 20.7559 10.8796 20.7559 11.887C20.7559 12.8944 21.5725 13.711 22.5799 13.711Z"
        fill="#FF4E50"
      />
    </g>
    <defs>
      <clipPath id="clip0_603_765">
        <rect width="40" height="40" rx="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
