import React from "react"

export const RemainedIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_603_752)">
      <path d="M45 -5H-5V45H45V-5Z" fill="#FF4E50" />
      <path
        d="M15.5934 -0.867432C13.2379 -0.301432 11.0374 0.658569 9.08594 1.97107L13.4899 9.56607L15.5934 -0.867432Z"
        fill="#F23A3E"
      />
      <path
        d="M23.0805 -1.23096C22.1695 -1.35646 21.2475 -1.44446 20.3025 -1.44446C17.7935 -1.44446 15.402 -0.964458 13.1875 -0.125958L13.4895 9.75504L15.786 8.49104L22.2635 1.32104L23.0805 -1.23096Z"
        fill="#003790"
      />
      <path
        d="M20.6927 -1.41296L13.4902 10H19.0157L25.8642 -0.619464C24.2447 -1.06996 22.4482 -1.38496 20.6927 -1.41296Z"
        fill="white"
      />
      <path
        d="M0.248234 20.3125C0.216734 20.773 0.177734 21.2315 0.177734 21.7C0.177734 25.951 1.50273 29.8875 3.75173 33.1365L27.1192 16.7875L0.248234 20.3125Z"
        fill="#F23A3E"
      />
      <path
        d="M32.1598 19.7485L23.4273 17.8805L1.07031 27.6375C3.60331 35.8535 11.2548 41.826 20.3028 41.826C20.8278 41.826 21.3423 41.786 21.8568 41.747C21.0783 39.299 20.2793 36.704 20.3393 36.572C20.4553 36.32 27.5053 28.0085 27.8403 27.4205C28.1758 26.834 32.1598 19.7485 32.1598 19.7485Z"
        fill="#BE2224"
      />
      <path
        d="M29.0786 16.6455C21.6106 16.6455 15.5566 22.699 15.5566 30.167C15.5566 37.636 21.6106 43.6895 29.0786 43.6895C36.5466 43.6895 42.6011 37.636 42.6011 30.167C42.6011 22.699 36.5466 16.6455 29.0786 16.6455Z"
        fill="white"
      />
      <path
        d="M31.8239 25.0945C31.8239 26.3795 30.7819 27.4205 29.4969 27.4205C28.2119 27.4205 27.1699 26.3795 27.1699 25.0945C27.1699 23.8085 28.2119 22.7675 29.4969 22.7675C30.7819 22.7675 31.8239 23.8085 31.8239 25.0945Z"
        fill="#003790"
      />
    </g>
    <defs>
      <clipPath id="clip0_603_752">
        <rect width="40" height="40" rx="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
